import React from 'react';
import { func } from 'prop-types';

import { requiredFieldArrayCheckbox } from '../../../util/validators';
import { FieldCheckboxGroup, NamedLink } from '../../../components';

import { FormattedMessage, intlShape } from '../../../util/reactIntl';

import css from './TermsAndConditions.module.css';

const TermsAndConditions = props => {
  const { intl } = props;

  const termsLink = (
    <NamedLink name="TermsOfServicePage" target="_blank">
      <FormattedMessage id="AuthenticationPage.termsAndConditionsTermsLinkText" />
    </NamedLink>
  );

  const privacyLink = (
    <NamedLink name="PrivacyPolicyPage" target="_blank">
      <FormattedMessage id="AuthenticationPage.termsAndConditionsPrivacyLinkText" />
    </NamedLink>
  );

  return (
    <div className={css.root}>
      <FieldCheckboxGroup
        name="terms"
        id="terms-accepted"
        optionLabelClassName={css.finePrint}
        options={[
          {
            key: 'tos-and-privacy',
            label: intl.formatMessage(
              { id: 'AuthenticationPage.termsAndConditionsAcceptText' },
              { termsLink, privacyLink }
            ),
          },
        ]}
        validate={requiredFieldArrayCheckbox(
          intl.formatMessage({ id: 'AuthenticationPage.termsAndConditionsAcceptRequired' })
        )}
      />
    </div>
  );
};

// TermsAndConditions.defaultProps = {};

TermsAndConditions.propTypes = {
  onOpenTermsOfService: func.isRequired,
  onOpenPrivacyPolicy: func.isRequired,
  intl: intlShape.isRequired,
};

export default TermsAndConditions;
