import React from 'react';
import loadable from '@loadable/component';

import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { propTypes } from '../../util/types';

import { H1 } from '../PageBuilder/Primitives/Heading';
import FallbackPage, { fallbackSections } from './FallbackPage';
import { ASSET_NAME } from './PrivacyPolicyPage.duck';
import { FormattedMessage } from '../../util/reactIntl';

import css from './PrivacyPolicyPage.module.css';
import { ExternalLink, NamedLink } from '../../components';

const PageBuilder = loadable(() =>
  import(/* webpackChunkName: "PageBuilder" */ '../PageBuilder/PageBuilder')
);
const SectionBuilder = loadable(
  () => import(/* webpackChunkName: "SectionBuilder" */ '../PageBuilder/PageBuilder'),
  {
    resolveComponent: components => components.SectionBuilder,
  }
);

// This "content-only" component can be used in modals etc.
const PrivacyPolicyContent = props => {
  const { inProgress, error, data } = props;

  if (inProgress) {
    return null;
  }

  // We don't want to add h1 heading twice to the HTML (SEO issue).
  // Modal's header is mapped as h2
  const hasContent = data => typeof data?.content === 'string';
  const exposeContentAsChildren = data => {
    return hasContent(data) ? { children: data.content } : {};
  };
  const CustomHeading1 = props => <H1 as="h2" {...props} />;

  const hasData = error === null && data;
  const sectionsData = hasData ? data : fallbackSections;

  return (
    <SectionBuilder
      {...sectionsData}
      options={{
        fieldComponents: {
          heading1: { component: CustomHeading1, pickValidProps: exposeContentAsChildren },
        },
        isInsideContainer: true,
      }}
    />
  );
};

// Presentational component for PrivacyPolicyPage
const PrivacyPolicyPageComponent = props => {
  const { inProgress, error } = props;

  return (
    <PageBuilder inProgress={inProgress} error={error} fallbackPage={<FallbackPage />}>
      <div className={css.privacyMain}>
        <h3>
          <FormattedMessage id="PrivacyPolicyPage.title" />
        </h3>
        <p>
          <FormattedMessage
            id="PrivacyPolicyPage.lastUpdated"
            values={{ date: 'August 25, 2022' }}
          />
        </p>

        <div className={css.privacyContent}>
          <p>
            <FormattedMessage id="PrivacyPolicyPage.intro" />
          </p>
          <h4>
            <FormattedMessage id="PrivacyPolicyPage.guidingPrinciplesTitle" />
          </h4>
          <p>
            <FormattedMessage id="PrivacyPolicyPage.guidingPrinciplesContent" />
          </p>
          <h4>
            <FormattedMessage id="PrivacyPolicyPage.contactUsTitle" />
          </h4>
          <p>
            <FormattedMessage
              id="PrivacyPolicyPage.contactUsContent"
              values={{
                emailLink: (
                  <ExternalLink href="mailto:info@softpix.io">
                    <FormattedMessage id="PrivacyPolicyPage.softpixEmail" />
                  </ExternalLink>
                ),
              }}
            />
          </p>
          <h4>
            <FormattedMessage id="PrivacyPolicyPage.definitionsTitle" />
          </h4>
          <FormattedMessage
            id="PrivacyPolicyPage.definitionsContent"
            values={{ p: item => <p>{item}</p>, b: boldItem => <b>{boldItem}</b> }}
          />
          <h4>
            <FormattedMessage id="PrivacyPolicyPage.whatPersonalDataTitle" />
          </h4>
          <FormattedMessage
            id="PrivacyPolicyPage.whatPersonalDataContent"
            values={{ p: item => <p>{item}</p>, b: boldItem => <b>{boldItem}</b> }}
          />
          <h4>
            <FormattedMessage id="PrivacyPolicyPage.howWeUsePersonalDataTitle" />
          </h4>
          <FormattedMessage
            id="PrivacyPolicyPage.howWeUsePersonalDataContent"
            values={{ p: item => <p>{item}</p>, b: boldItem => <b>{boldItem}</b> }}
          />
          <h4>
            <FormattedMessage id="PrivacyPolicyPage.howWeSharePersonalDataTitle" />
          </h4>
          <FormattedMessage
            id="PrivacyPolicyPage.howWeSharePersonalDataContent"
            values={{ p: item => <p>{item}</p>, b: boldItem => <b>{boldItem}</b> }}
          />

          <h4>
            <FormattedMessage id="PrivacyPolicyPage.howWeRetainPersonalDataTitle" />
          </h4>
          <p>
            <FormattedMessage
              id="PrivacyPolicyPage.howWeRetainPersonalDataContent"
              values={{ p: item => <p>{item}</p> }}
            />
          </p>
          <h4>
            <FormattedMessage id="PrivacyPolicyPage.privacyRightsTitle" />
          </h4>
          <p>
            <FormattedMessage
              id="PrivacyPolicyPage.privacyRightsContent"
              values={{ p: item => <p>{item}</p> }}
            />
          </p>
          <h4>
            <FormattedMessage id="PrivacyPolicyPage.europeanDataProtectionTitle" />
          </h4>
          <FormattedMessage
            id="PrivacyPolicyPage.europeanDataProtectionContent"
            values={{ p: item => <p>{item}</p>, b: boldItem => <b>{boldItem}</b> }}
          />

          <h4>
            <FormattedMessage id="PrivacyPolicyPage.legalBasisTitle" />
          </h4>
          <FormattedMessage
            id="PrivacyPolicyPage.legalBasisContent"
            values={{ p: item => <p>{item}</p>, b: boldItem => <b>{boldItem}</b> }}
          />

          <h4>
            <FormattedMessage id="PrivacyPolicyPage.internationalDataTransfersTitle" />
          </h4>
          <p>
            <FormattedMessage
              id="PrivacyPolicyPage.internationalDataTransfersContent"
              values={{ p: item => <p>{item}</p> }}
            />
          </p>
          <br />
          <h4>
            <FormattedMessage id="PrivacyPolicyPage.californiaPrivacyRightsTitle" />
          </h4>

          <FormattedMessage
            id="PrivacyPolicyPage.californiaPrivacyRightsContent"
            values={{ p: item => <p>{item}</p>, b: boldItem => <b>{boldItem}</b> }}
          />

          <h4>
            <FormattedMessage id="PrivacyPolicyPage.changesToPrivacyStatementTitle" />
          </h4>
          <p>
            <FormattedMessage id="PrivacyPolicyPage.changesToPrivacyStatementContent" />
          </p>
        </div>
        <div>
          <p>
            <NamedLink name="TermsOfServicePage" target="_blank">
              <FormattedMessage id="TermsOfServicePage.termsLink" />
            </NamedLink>
            &nbsp;-&nbsp;
            <FormattedMessage id="TermsOfServicePage.privacyLink" />
            &nbsp;-&nbsp;
            <NamedLink name="LicensesPage" target="_blank">
              <FormattedMessage id="TermsOfServicePage.licensesLink" />
            </NamedLink>
          </p>
        </div>
      </div>
    </PageBuilder>
  );
};

PrivacyPolicyPageComponent.propTypes = {
  pageAssetsData: object,
  inProgress: bool,
  error: propTypes.error,
};

const mapStateToProps = state => {
  const { pageAssetsData, inProgress, error } = state.hostedAssets || {};
  return { pageAssetsData, inProgress, error };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const PrivacyPolicyPage = compose(connect(mapStateToProps))(PrivacyPolicyPageComponent);

const PRIVACY_POLICY_ASSET_NAME = ASSET_NAME;
export { PRIVACY_POLICY_ASSET_NAME, PrivacyPolicyPageComponent, PrivacyPolicyContent };

export default PrivacyPolicyPage;
