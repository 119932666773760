import React from 'react';
import { bool, node } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';
import { Form, PrimaryButton, FieldTextInput, FieldCheckbox } from '../../../components';

import css from './SignupForm.module.css';
import FieldPasswordInput from '../../../components/FieldPasswordInput/FieldPasswordInput';

const SignupFormComponent = props => {
  return (
    <FinalForm
      {...props}
      mutators={{ ...arrayMutators }}
      render={fieldRenderProps => {
        const {
          rootClassName,
          className,
          formId,
          handleSubmit,
          inProgress,
          invalid,
          intl,
          termsAndConditions,
          form,
        } = fieldRenderProps;

        // email
        const emailRequired = validators.required(
          intl.formatMessage({
            id: 'SignupForm.emailRequired',
          })
        );
        const emailValid = validators.emailFormatValid(
          intl.formatMessage({
            id: 'SignupForm.emailInvalid',
          })
        );

        // password
        const passwordRequiredMessage = intl.formatMessage({
          id: 'SignupForm.passwordRequired',
        });
        const passwordMinLengthMessage = intl.formatMessage(
          {
            id: 'SignupForm.passwordTooShort',
          },
          {
            minLength: validators.PASSWORD_MIN_LENGTH,
          }
        );
        const passwordMaxLengthMessage = intl.formatMessage(
          {
            id: 'SignupForm.passwordTooLong',
          },
          {
            maxLength: validators.PASSWORD_MAX_LENGTH,
          }
        );
        const passwordMinLength = validators.minLength(
          passwordMinLengthMessage,
          validators.PASSWORD_MIN_LENGTH
        );
        const passwordMaxLength = validators.maxLength(
          passwordMaxLengthMessage,
          validators.PASSWORD_MAX_LENGTH
        );
        const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
        const passwordValidators = validators.composeValidators(
          passwordRequired,
          passwordMinLength,
          passwordMaxLength,
          validators.containsUppercase(
            intl.formatMessage({ id: 'SignupForm.passwordUppercaseRequired' })
          ),
          validators.containsNumber(
            intl.formatMessage({ id: 'SignupForm.passwordNumberRequired' })
          ),
          validators.containsSpecialChar(
            intl.formatMessage({ id: 'SignupForm.passwordSpecialCharRequired' })
          )
        );

        const passwordValue = form.getFieldState('password')?.value;

        //displayName
        const displayNameMaxLengthMessage = intl.formatMessage(
          {
            id: 'SignupForm.displayNameTooLong',
          },
          {
            maxLength: validators.DISPLAY_NAME_MAX_LENGTH,
          }
        );

        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = inProgress;
        const submitDisabled = invalid || submitInProgress;

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <div>
              <FieldTextInput
                type="email"
                id={formId ? `${formId}.email` : 'email'}
                name="email"
                autoComplete="email"
                label={intl.formatMessage({
                  id: 'SignupForm.emailLabel',
                })}
                placeholder={intl.formatMessage({
                  id: 'SignupForm.emailPlaceholder',
                })}
                validate={validators.composeValidators(emailRequired, emailValid)}
              />
              <div className={css.name}>
                <FieldTextInput
                  className={css.firstNameRoot}
                  type="text"
                  id={formId ? `${formId}.fname` : 'fname'}
                  name="fname"
                  autoComplete="given-name"
                  label={intl.formatMessage({
                    id: 'SignupForm.firstNameLabel',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'SignupForm.firstNamePlaceholder',
                  })}
                  validate={validators.required(
                    intl.formatMessage({
                      id: 'SignupForm.firstNameRequired',
                    })
                  )}
                />
                <FieldTextInput
                  className={css.lastNameRoot}
                  type="text"
                  id={formId ? `${formId}.lname` : 'lname'}
                  name="lname"
                  autoComplete="family-name"
                  label={intl.formatMessage({
                    id: 'SignupForm.lastNameLabel',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'SignupForm.lastNamePlaceholder',
                  })}
                  validate={validators.required(
                    intl.formatMessage({
                      id: 'SignupForm.lastNameRequired',
                    })
                  )}
                />
              </div>

              <div className={css.displayNameWrapper}>
                <FieldTextInput
                  className={css.displayName}
                  type="text"
                  id={formId ? `${formId}.displayName` : 'displayName'}
                  name="displayName"
                  autoComplete="display-name"
                  label={intl.formatMessage({
                    id: 'SignupForm.displayNameLabel',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'SignupForm.displayNamePlaceholder',
                  })}
                  validate={validators.composeValidators(
                    validators.required(
                      intl.formatMessage({
                        id: 'SignupForm.displayNameRequired',
                      })
                    ),
                    validators.maxLength(
                      displayNameMaxLengthMessage,
                      validators.DISPLAY_NAME_MAX_LENGTH
                    )
                  )}
                />
              </div>

              <FieldPasswordInput
                id={formId ? `${formId}.password` : 'password'}
                name="password"
                autoComplete="new-password"
                label={intl.formatMessage({
                  id: 'SignupForm.passwordLabel',
                })}
                placeholder={intl.formatMessage({
                  id: 'SignupForm.passwordPlaceholder',
                })}
                validate={passwordValidators}
                isVisibilityButtonVisible={!!passwordValue}
              />
            </div>

            <div className={css.bottomWrapper}>
              {termsAndConditions}
              <FieldCheckbox
                id={formId ? `${formId}.subscribedToNewsletter` : 'subscribedToNewsletter'}
                name="subscribedToNewsletter"
                label={intl.formatMessage({ id: 'SignupForm.chooseNewsletterMessage' })}
                className={css.newsletterMessage}
                value="subscribedToNewsletter"
              />
              <PrimaryButton
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                className={css.signupButton}
              >
                <FormattedMessage id="SignupForm.signUp" />
              </PrimaryButton>
            </div>
          </Form>
        );
      }}
    />
  );
};

SignupFormComponent.defaultProps = { inProgress: false };

SignupFormComponent.propTypes = {
  inProgress: bool,
  termsAndConditions: node.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
