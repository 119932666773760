import React from 'react';
import loadable from '@loadable/component';

import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { propTypes } from '../../util/types';

import { H1 } from '../PageBuilder/Primitives/Heading';

import FallbackPage, { fallbackSections } from './FallbackPage';
import { ASSET_NAME } from './TermsOfServicePage.duck';

import css from './TermsOfServicePage.module.css';
import { FormattedMessage } from '../../util/reactIntl';
import { ExternalLink, NamedLink } from '../../components';

const PageBuilder = loadable(() =>
  import(/* webpackChunkName: "PageBuilder" */ '../PageBuilder/PageBuilder')
);
const SectionBuilder = loadable(
  () => import(/* webpackChunkName: "SectionBuilder" */ '../PageBuilder/PageBuilder'),
  {
    resolveComponent: components => components.SectionBuilder,
  }
);

// This "content-only" component can be used in modals etc.
const TermsOfServiceContent = props => {
  const { inProgress, error, data } = props;

  if (inProgress) {
    return null;
  }

  // We don't want to add h1 heading twice to the HTML (SEO issue).
  // Modal's header is mapped as h2
  const hasContent = data => typeof data?.content === 'string';
  const exposeContentAsChildren = data => {
    return hasContent(data) ? { children: data.content } : {};
  };
  const CustomHeading1 = props => <H1 as="h2" {...props} />;

  const hasData = error === null && data;
  const sectionsData = hasData ? data : fallbackSections;

  return (
    <SectionBuilder
      {...sectionsData}
      options={{
        fieldComponents: {
          heading1: { component: CustomHeading1, pickValidProps: exposeContentAsChildren },
        },
        isInsideContainer: true,
      }}
    />
  );
};

// Presentational component for TermsOfServicePage
const TermsOfServicePageComponent = props => {
  const { inProgress, error } = props;

  return (
    <>
      <PageBuilder inProgress={inProgress} error={error} fallbackPage={<FallbackPage />}>
        <div className={css.termsMain}>
          <h1>
            <FormattedMessage id="TermsOfServicePage.title" />
          </h1>

          <h4>
            <FormattedMessage id="TermsOfServicePage.updatedDate" />
          </h4>

          <div className={css.termsContent}>
            <h4>
              <FormattedMessage id="TermsOfServicePage.termsOfUseTitle" />
            </h4>

            <FormattedMessage
              id="TermsOfServicePage.termsOfUseContent"
              values={{ p: item => <p>{item}</p> }}
            />

            <h4>
              <FormattedMessage id="TermsOfServicePage.waiverTitle" />
            </h4>

            <FormattedMessage
              id="TermsOfServicePage.waiverContent"
              values={{ p: item => <p>{item}</p> }}
            />

            <h4>
              <FormattedMessage id="TermsOfServicePage.digitalContentTitle" />
            </h4>

            <FormattedMessage
              id="TermsOfServicePage.digitalContentContent"
              values={{ p: item => <p>{item}</p> }}
            />

            <h4>
              <FormattedMessage id="TermsOfServicePage.contentDeliveryTitle" />
            </h4>

            <FormattedMessage
              id="TermsOfServicePage.contentDeliveryContent"
              values={{ p: item => <p>{item}</p> }}
            />

            <h4>
              <FormattedMessage id="TermsOfServicePage.registrationTitle" />
            </h4>

            <FormattedMessage
              id="TermsOfServicePage.registrationContent"
              values={{
                infoEmail: (
                  <ExternalLink href="mailto:info@softpix.io">
                    <FormattedMessage id="PrivacyPolicyPage.softpixEmail" />
                  </ExternalLink>
                ),
                p: item => <p>{item}</p>,
              }}
            />

            <h4>
              <FormattedMessage id="TermsOfServicePage.agreementTitle" />
            </h4>

            <p>
              <FormattedMessage id="TermsOfServicePage.agreementContent" />
            </p>

            <h4>
              <FormattedMessage id="TermsOfServicePage.cancellationTitle" />
            </h4>

            <p>
              <FormattedMessage id="TermsOfServicePage.cancellationContent" />
            </p>

            <h4>
              <FormattedMessage id="TermsOfServicePage.infoProvidedTitle" />
            </h4>

            <FormattedMessage
              id="TermsOfServicePage.infoProvidedContent"
              values={{ p: item => <p>{item}</p> }}
            />

            <h4>
              <FormattedMessage id="TermsOfServicePage.customerResponsibilityTitle" />
            </h4>

            <p>
              <FormattedMessage id="TermsOfServicePage.customerResponsibilityContent" />
            </p>

            <h4>
              <FormattedMessage id="TermsOfServicePage.rightsTitle" />
            </h4>

            <h4>
              <FormattedMessage id="TermsOfServicePage.copyrightTitle" />
            </h4>

            <p>
              <FormattedMessage id="TermsOfServicePage.copyrightContent" />
            </p>
          </div>
          <div>
            <p>
              <span>
                <FormattedMessage id="TermsOfServicePage.termsLink" /> -&nbsp;
                <NamedLink name="PrivacyPolicyPage" target="_blank">
                  <FormattedMessage id="TermsOfServicePage.privacyLink" />
                </NamedLink>
                &nbsp;-&nbsp;
                <NamedLink name="LicensesPage" target="_blank">
                  <FormattedMessage id="TermsOfServicePage.licensesLink" />
                </NamedLink>
              </span>
            </p>
          </div>
        </div>
      </PageBuilder>
    </>
  );
};

TermsOfServicePageComponent.propTypes = {
  pageAssetsData: object,
  inProgress: bool,
  error: propTypes.error,
};

const mapStateToProps = state => {
  const { pageAssetsData, inProgress, error } = state.hostedAssets || {};
  return { pageAssetsData, inProgress, error };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const TermsOfServicePage = compose(connect(mapStateToProps))(TermsOfServicePageComponent);

const TOS_ASSET_NAME = ASSET_NAME;
export { TOS_ASSET_NAME, TermsOfServicePageComponent, TermsOfServiceContent };

export default TermsOfServicePage;
